<template>
<div class="main">
  <VxeOrgTree service="/sys/sysOrganization/treeQueryAuthCar/communtiy" class="main_left" />
  <div class="main_right">
    <VxeBasicTable ref="xGrid" :gridOptions="gridOptions" :formOptions="formOptions" :searchOptions="searchOptions" :defaultTrigger="false">
    </VxeBasicTable>
  </div>
</div>
</template>

<script>
export default {
  data() {
    return {
      currNode: {},
      gridOptions: {
        // 列表功能按钮组
        toolbarConfig: {
          buttons: [{
              code: "create",
              name: "新增",
              status: "primary",
              events: {
                click: () => {
                  if (this.currNode.type == '1001') {
                    let { items, data } = this.formOptions;
                    this.$Tools.enabledEvent(items, ["parkingSpaceNum"], false)
                    data["parentId"] = this.currNode.id;
                    data["communityId"] = this.currNode.communityId;
                    this.$refs.xGrid.insertEvent();
                  } else {
                    this.$message.error('请选择小区')
                  }
                }
              }
            },
            { code: "batchDelete", name: "批量删除", status: "danger" },
          ],
          refresh: {
            query: () => {
              this.$refs.xGrid.getLists();
            },
          },
        },
        // 列表字段配置
        columns: [{
            type: "seq",
            width: 60,
          },
          {
            type: "checkbox",
            width: 60,
          },
          {
            field: "name",
            title: "停车场名称",
            minWidth: 160,
            editRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: "请输入停车场名称",
              },
            },
          },
          {
            field: "num",
            title: "停车场编号",
            minWidth: 160,
            editRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: "请输入停车场编号",
              },
            },
          },
          {
            field: "typeCd",
            title: "停车场类型",
            minWidth: 160,
            editRender: {
              name: '$select',
              optionTypeCode: "parkingType",
              props: {
                placeholder: "请选择停车场类型",
              },
            },
          },
          {
            field: "parkingSpaceNum",
            title: "车位数量",
            minWidth: 120,
            editRender: {
              name: '$input',
              enabled: false,
              props: {
                placeholder: "请输入车位数量",
              },
            },
          },
          // {
          //   field: "enabled",
          //   title: "状态",
          //   width: 120,
          //   cellRender: {
          //     name: "$switch",
          //     optionTypeCode: "enabledStatus",
          //     props: {
          //       openLabel: "启用",
          //       closeLabel: "禁用",
          //       openValue: "1",
          //       closeValue: "0"
          //     },
          //     events: {
          //       change: ({ row }) => {
          //         this.$refs.xGrid.submitEvent(row);
          //       }
          //     }
          //   },
          // },
          {
            fixed: "right",
            title: "操作",
            width: 160,
            editRender: {
              name: "$VxeGridButtons",
              lists: [{
                  status: "primary",
                  label: "修改",
                  eventName: "queryEvent",
                  name: 'edit',
                  events: {
                    click: (row) => {
                      let { items } = this.formOptions;
                      this.$Tools.enabledEvent(items, ["parkingSpaceNum"], true);
                    }
                  }
                },
                {
                  status: "danger",
                  label: "删除",
                  name: 'delete',
                  eventName: "removeEvent",
                },
              ],
            },
          },
        ],
        editConfig: {},
        exportConfig: {},
        importConfig: {},
      },
      formOptions: {
        // 新增功能字段配置
        width: 800,
        titleWidth: 130,
        items: [{
            field: "parentId",
            title: "所属上级",
            span: 24,
            itemRender: {
              name: "$VxeSelectTree",
              props: {
                placeholder: "请选择所属上级",
              },
              expandAll: true,
              events: {
                click: (params, node) => {
                  let { data } = params;
                  if (node.type == '1001') {
                    data["communityId"] = node.id;
                    data["parentId"] = node.id;
                  } else {
                    this.$message.error('请选择小区')
                  }
                },
              }
            },
          },
          {
            field: "name",
            title: "停车场名称",
            span: 12,
            itemRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: "请输入停车场名称",
              },
            },
          },
          {
            field: "num",
            title: "停车场编号",
            span: 12,
            itemRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: "请输入停车场编号",
              },
            },
          },
          {
            field: "parkingSpaceNum",
            title: "车位数量",
            span: 12,
            itemRender: {
              name: '$input',
              props: {
                type: "integer",
                min: 0,
                max: 999,
                maxlength: 3,
                placeholder: "请输入车位数量",
              },
            },
          },
          {
            field: "typeCd",
            title: "停车场类型",
            span: 12,
            itemRender: {
              name: '$select',
              optionTypeCode: "parkingType",
              props: {
                placeholder: "请选择停车场类型",
              },
            },
          },
          {
            field: "enabled",
            title: "状态",
            span: 12,
            itemRender: {
              name: "$select",
              optionTypeCode: "enabledStatus",
              props: {
                placeholder: "选择状态",
              },
            },
          },
          {
            field: 'remark',
            title: '描述',
            span: 24,
            itemRender: {
              name: '$textarea',
              props: {
                showWordCount: true,
                maxlength: "300",
                resize: "none",
                placeholder: '请输入描述内容'
              }
            }
          },
          {
            align: "center",
            span: 24,
            itemRender: {
              name: "$buttons",
              children: [{
                  props: {
                    type: "submit",
                    content: "提交",
                    status: "primary",
                  },
                },
                {
                  props: {
                    type: "reset",
                    content: "取消",
                  },
                },
              ],
            },
          },
        ],
        //表单校验规则
        rules: {
          name: [
            { required: true, message: "请输入停车场名称" },
            { min: 2, max: 30, message: "长度在 2 到 30 个字符" },
          ],
          num: [
            { required: true, message: "请输入停车场编号" }
          ],
          typeCd: [{ required: true, message: "请选择停车场类型" }],
          parkingSpaceNum: [{ required: true, message: "请输入车位数量" }],
          enabled: [{ required: true, message: "请选择状态" }],
          parentId: [{ required: true, message: "请选择所属公司" }],
        },
        // 新增功能字段初始化
        data: {
          enabled: "1",
          typeCd: "1",
          parkingSpaceNum: "",
          type: '2001',
          communityId: ''
        },
      },
      // 查询功能字段配置
      searchOptions: {
        items: [{
            field: "name",
            title: "停车场名称",
            itemRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: "请输入停车场名称",
              },
            },
          },
          {
            field: "typeCd",
            title: "停车场类型",
            itemRender: {
              name: "$select",
              optionTypeCode: "parkingType",
              props: {
                placeholder: "请选择停车场类型",
              },
            },
          },
          {
            field: "enabled",
            title: "状态",
            folding: true,
            itemRender: {
              name: "$select",
              optionTypeCode: "enabledStatus",
              props: {
                placeholder: "选择状态",
              },
            },
          },
          {
            align: "center",
            collapseNode: true,
            itemRender: {
              name: "$buttons",
              children: [{
                  props: {
                    type: "submit",
                    content: "查询",
                    status: "primary",
                  },
                },
                {
                  props: {
                    type: "reset",
                    content: "重置",
                  },
                },
              ],
            },
          },
        ],
        data: {
          name: "",
          typeCd: '',
          enabled: "",
        },
      },
    };
  },

  methods: {
    handleNodeClick(treeData, node) {
      this.currNode = node;
      this.$Tools.buildDataToGrid(this.gridOptions.columns, treeData, "parentId");
      this.$Tools.buildDataToGrid(this.formOptions.items, treeData, "parentId");
      this.searchOptions.data["parentId"] = node.id;
      this.$refs.xGrid.getLists();
    }
  },
};
</script>

<style lang="scss" scoped>
</style>
